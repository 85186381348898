import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {    
    metaMolienda(queryParams){
        return new Promise((resolve,reject) =>{
            axios
                .get('/goal/molienda', queryParams)
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }
  },
}