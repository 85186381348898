<template>
  <b-card>
    <b-row>
      <b-col
        md="4"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-1">Paginación</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="8"
        class="my-1"
      >
        <b-form-group
          label="Ordenar"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">
                  --Nada--
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        class="my-1"
      >
        <b-form-group
          label="Filtrar"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                variant="gradient-primary"
                @click="filter = ''"
              >
                Limpiar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          id="MetasMolienda"
          class="text-center"
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          empty-text="No se encontrarón resultados"
          @filtered="onFiltered"
        >
          <template #cell(fecha)="data">
            {{ DatePretty(data.item.fecha_relativa) }}
          </template>
          <template
            v-if="false"
            #cell(Acciones)="data"
          >
            <b-button
              v-if="$route.meta.currentUserRol == 'master' || $route.meta.currentUserRol == 'admin'"
              variant="gradient-primary"
              class="btn-icon rounded-circle"
              @click="editItem({
                id:data.item.ID,
                value:data.item.value,
                tiempo_perdido: data.item.tiempo_perdido,
                rendimiento: data.item.rendimiento,
                dia_zafra: data.item.dia_zafra
              })"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="$route.meta.currentUserRol == 'master' || $route.meta.currentUserRol == 'admin'"
              variant="danger"
              class="btn-icon rounded-circle ml-0.5"
              @click="deleteItem(data.item.ID)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rowsCount ? rowsCount : totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BTable, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard,
} from 'bootstrap-vue'

export default {
  name: 'TablaLab',
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
  },
  props: {
    items: Array,
    totalRows: Number,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [3, 5, 10, 15, 20, 25, 50, 100],
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        'ID',
        // A column that needs custom formatting
        { key: 'zafra', label: 'Zafra' },
        { key: 'dia_zafra', label: 'Dia Zafra', sortable: true },
        { key: 'fecha', label: 'Fecha' },
        { key: 'rendimiento', label: 'Rendimiento (kg/t)' },
        { key: 'tiempo_perdido', label: 'Tiempo Perdido' },
        { key: 'value', label: 'Rate de Molienda', sortable: true },
        // 'Acciones',
      ],
      rowsCount: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length
      this.rowsCount = filteredItems.length
      this.currentPage = 1
    },
    deleteItem(item) {
      this.$emit('delete', item)
    },
    editItem(item) {
      this.$emit('edit', item)
    },
    DatePretty(item) {
      const date = new Date(item)
      const hora = (`0${date.getUTCHours()}`).slice(-2)
      const minutos = (`0${date.getMinutes()}`).slice(-2)
      const segundos = (`0${date.getSeconds()}`).slice(-2)
      return `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`
    },
  },
}
</script>
<style>
#MetasMolienda tr{
    height: 58px;
}
</style>
