<template>
    <div>
        <data-view :items="items" :totalRows="items.length" @delete="deleteItem" @edit="editItem" />
        <edit-box :datos="editData" @success="editConfirm" />
    </div>
</template>
<script>
import DataView from './Data.vue'
import Molienda from '@/../apis/goals'
import editBox from './Edit.vue'
export default {
    name: 'MetasMolienda',
    components:{
        DataView,
        editBox
    },
    data (){
        return{
            items: [],
            editData: {}
        }
    },
    mounted(){
        Molienda.actions.metaMolienda().then((values)=>{
            this.items = values.data.data
        })
    },
    methods:{
        deleteItem(event){
            this.$bvModal
            .msgBoxConfirm('Por favor confirmar que desea eliminar el dato.', {
                title: 'Confirmar',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Confirmar',
                cancelTitle: 'Cancelar',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            .then(value => {
                if(value){
                    return
                    const obj = {id:event}
                    laboratorio.actions.eliminarRegistro(obj).then((response)=>{
                        if(response.status == 200){
                            this.filtrar(this.tempSave)
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                title: 'Dato Eliminado.',                  
                                icon: 'TrashIcon',
                                variant: 'danger',
                                },
                            })
                        }
                    })
                }
            })
        },
        editItem(event){
            this.editData = event            
            this.$bvModal.show('editEntry')            
        },
        editConfirm(event){
            if(event){                
                this.$toast({
                component: ToastificationContent,
                    props: {
                        title: 'Cambios Guardados.',                  
                        icon: 'SaveIcon',
                        variant: 'success',
                    },
                })
            }else{
                this.$toast({
                component: ToastificationContent,
                    props: {
                        title: 'Cambios Guardados.',                  
                        icon: 'AlerttriangleIcon',
                        variant: 'success',
                    },
                })
            }            
        }
    }
}
</script>
