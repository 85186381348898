<template>
    <b-modal
      id="editEntry"
      :title="'Dia Zafra '+ datos.dia_zafra"
      ok-title="Guardar Cambios"
      cancel-variant="outline-secondary"
      cancel-title="Cancelar"
      @ok="confirmEdit"
      class="capitalize"
    >    
      <b-form>
          <b-form-group
          label="Valor Actual"
          label-for="curretValue"
        >
          <b-form-input
            id="curretValue"
            placeholder="Valor Actual"
            v-model="datos.value"
            :disabled="true"
          />
        </b-form-group> 
        <b-form-group
          label="Ingresar nuevo valor"
          label-for="dataValue"
        >
          <b-form-input
            id="dataValue"
            placeholder="Valor"
            v-model="newValue"
            type="number"
          />
        </b-form-group>        
      </b-form>
    </b-modal>
</template>
<script>
import {
  BButton, BModal, VBModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import laboratorio from '@/../apis/Laboratorio'
export default {
    name: 'PopUpEdit',
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    methods:{
        confirmEdit(){
            if(this.newValue){                
                this.datos.new = Number(this.newValue)
                laboratorio.actions.actualizarRegistro(this.datos).then((response)=>{                    
                    this.$emit('success', true)
                }).catch((error)=>{
                    this.$emit('success', false)                    
                })
            }
        }
    },
    data(){
        return{
            newValue:null
        }
    },
    props:{
        datos:{
            required: true
        }
    }
}
</script>
<style>
#editEntry___BV_modal_title_{
    text-transform: capitalize;
}
</style>